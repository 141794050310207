<template>
  <article class="teaserList" :class="{ highlight: highlight }">
    <div class="layout">
      <TeaserListFig v-if="fig" :content="fig" />

      <TeaserListText :content="{ title, to, infoTags, datelist }" />

      <TeaserListLike
        v-if="isLocalStorageAvailable()"
        :model-value="isLiked"
        @click="toggleLike"
      />
    </div>
  </article>
</template>

<script lang="ts" setup>
import isLocalStorageAvailable from '../../../utils/isLocalStorageAvailable';
import type { TeaserList } from './models';

const props = withDefaults(
  defineProps<{
    content: TeaserList;
    highlight?: boolean;
  }>(),
  { highlight: false }
);

const fig = toRef(() => props.content.fig);
const title = toRef(() => props.content.title);
const to = toRef(() => props.content.to);
const infoTags = toRef(() => props.content.infoTags);
const datelist = toRef(() => props.content.datelist);
const id = toRef(() => props.content.id);
const moduleType = toRef(() => props.content.moduleType);

const storage = useLocalStorage<string[]>('bookmarks', []);

const isLiked = computed(() => {
  if (!moduleType.value || !id.value) return false;
  const itemKey = `${moduleType.value}_${id.value}`;

  return storage.value.includes(itemKey);
});

const toggleLike = () => {
  if (!moduleType.value || !id.value) return;

  const itemKey = `${moduleType.value}_${id.value}`;

  if (isLiked.value) {
    storage.value = storage.value.filter((item) => item !== itemKey);
  } else {
    storage.value = [...storage.value, itemKey];
  }
};
</script>

<style src="./List.scss" scoped lang="scss"></style>
